import React, {useState} from 'react'
import {Button, IconButton, Typography} from "@mui/material";
import {Add, Remove} from "@mui/icons-material";
import './NumberInput.scss'


interface props {
    start?: string,
    max?: number,
    min?: number,
    handleChange?: any,
}

export default function NumberInput({
                                        start = '1',
                                        max = 10,
                                        min = 1,
                                        handleChange = () => {
                                        }
                                    }: props) {

    const [count, setCount] = useState<string>(start)


    const addCount = () => {
        setCount(count => (parseInt(count) + 1).toString())
    }
    const removeCount = () => {
        setCount(count => (parseInt(count) - 1).toString())

    }
    handleChange(count)
    return (
        <div className={'numberInput'}>
            <div className={'toggleButton remove'}>
                <IconButton disabled={parseInt(count) === min}
                            onClick={removeCount} size={'small'}>
                    <Remove/>
                </IconButton>
            </div>
            <div className={'value'}>
                <Typography>
                    {count}
                </Typography>
            </div>
            <div className={'toggleButton add'}>
                <IconButton disabled={parseInt(count) === max} size={'small'}
                            onClick={addCount}>
                    <Add/>
                </IconButton>
            </div>
        </div>
    )
}