import axios from 'axios'
import {State} from './components/Order/OrderStep/OrderStep'
import {shopItem} from './reducer/ShopReducer'

export interface Product {
    id: number,
    name: string,
    price: number,
    count?: number,
    media_src?: string,
    created_at?: string,
    updated_at?: string,
}

const API = axios.create({
    baseURL: 'https://new-shop.ppm-dev.de/api/v1',
    withCredentials: true,

})

API.defaults.headers.post['Access-Control-Allow-Origin'] = 'localhost:3000;localhost:8000'
API.defaults.headers.post['Content-Type'] = 'application/json'
export {API}


export async function getProducts() {
    const {data}: { data: Product[] } = await API.get<Product[]>('products')
    return data
}

export async function getPopUp(productId: number) {
    const data = await API.get(`popups/products/${productId}`)
    return data
}

export async function orderProducts(order: { order: shopItem[], information: State, data?: any }) {
    const returnData = await API.post('order', order)
    console.log('API ORDER : ', returnData)
}