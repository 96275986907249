import {createTheme, responsiveFontSizes} from '@mui/material'
import Montserrat from './fonts/Montserrat-Regular.ttf'
import MontserratOTF from './fonts/Montserrat-Regular.otf'


export const theme = responsiveFontSizes(
    createTheme({
        palette: {
            primary: {
                main: '#0A4F95',
            },
        },
        typography: {
            fontFamily: 'Montserrat',
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: `
               font-family:'Montserrat',`
            }
        }
    })
)