import * as React from 'react'
import {Container, Button, Modal, Typography, Grid, IconButton, CircularProgress} from '@mui/material'
import {useState} from 'react'
import PopUpHeader from './components/PopUpHeader'
import './PopUp.scss'
import PopUpContent from './components/PopUpContent'
import Image, {MediaProps} from '../shared/Image'
import {Close} from '@mui/icons-material'


export const PopupContext = React.createContext({
    onClose: () => {
    },
})

export interface PopUpProps {
    isOpen?: boolean,
    productId: number,
}

export default function PopUp({
                                  isOpen = false,
                                  data,
                                  loading,
                                  onClose
                              }: { isOpen?: boolean, data: any, loading?: boolean, onClose: () => void }) {

    console.log(data)


    const handleClose = () => onClose()


    return (
        <PopupContext.Provider value={{onClose: handleClose}}>
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={'popup-modal'}
            >
                {loading ?
                    <Container sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <CircularProgress/>
                    </Container>
                    :
                    <Container>
                        <Grid container className={'popup'} alignItems={'stretch'}>
                            <Container className="popupClose">
                                <Button onClick={handleClose} endIcon={<Close/>}>Schließen</Button>
                            </Container>
                            <Grid item xs={12} md={6} xl={4} className={'popupMedia'}>
                                <Image src={data.media_src} alt={data.media_alt}/>
                            </Grid>
                            <Grid item xs={12} md={6} xl={8}>
                                <PopUpContent data={data}/>
                            </Grid>
                        </Grid>
                    </Container>
                }
            </Modal>
        </PopupContext.Provider>
    )
}
