import React, {useEffect, useState} from 'react'
import PopUp from '../../PopUp/PopUp'
import {getPopUp} from '../../../Api'


export default function ProductPopUp({
                                         productId,
                                         open,
                                         onClose
                                     }: { productId: number, open: boolean, onClose: () => void }) {

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})

    useEffect(() => {
        getData(productId)
            .then(res => {
                setData(res.data)
                setLoading(false)
            })
            .catch(e => {
                console.error(e.response)
            })
    }, [])

    return (
        <PopUp isOpen={open} loading={loading} data={data} onClose={onClose}/>
    )

}

async function getData(productId: number) {
    const data = await getPopUp(productId)
    return data
}