import {combineReducers} from "@reduxjs/toolkit";
import ShopReducer from "./ShopReducer";
import StepperReducer from "./StepperReducer";
import {shopItem} from "./ShopReducer";
import {StepperType} from "./StepperReducer";


export interface actionType {
    type: string,
    payload: any,
}

export interface RootState {
    shop: shopItem,
    stepper: StepperType,
}

export const Reducer = combineReducers({shop: ShopReducer, stepper: StepperReducer})
