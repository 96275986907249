import {STEPPER_ACTIONS} from '../actions/ACTION_TYPES'
import {actionType} from './combineReducer'


export interface StepperType {
    activeStep: number,
    lastStep: boolean,
    steps: string[],
    data?: Object
    wichtel?: Object,
}

const defaultState: StepperType = {
    activeStep: 0,
    lastStep: false,
    steps: ['Einkaufswagen', 'Bezahlen', 'Bestellen'],
    data: {},
    wichtel: {}
}

export default function StepperReducer(state = defaultState, action: actionType) {
    switch (action.type) {
        case STEPPER_ACTIONS.SET_PREV: {
            if (state.activeStep !== 0) {
                return {
                    ...state,
                    activeStep: state.activeStep - 1,
                    data: action.payload,
                }
            } else return state
        }
        case STEPPER_ACTIONS.SET_NEXT: {
            if (state.activeStep !== state.steps.length + 1) {
                return {
                    ...state,
                    activeStep: state.activeStep + 1,
                    data: action.payload,
                }
            } else return state
        }
        case STEPPER_ACTIONS.SET_STEP: {
            console.log('SET STEP')
            if (action.payload < state.steps.length && action.payload >= 0) {
                return {
                    ...state,
                    activeStep: action.payload,
                }
            } else return state
        }
        case STEPPER_ACTIONS.SET_DATA: {
            return {
                ...state,
                wichtel: action.payload,
            }
        }

        default:
            return state
    }
}