import React from 'react'
import {Container, Typography} from '@mui/material'
import PopUpText from './PopUpText'
import PopUpActions from './PopUpActions'
import PopUpInfo from './PopUpInfo'

export default function PopUpContent({data}: { data: any }) {

    return (
        <Container className={'popupContent'}>
            <PopUpText data={data}/>
            <PopUpInfo data={data}/>
            <PopUpActions/>

        </Container>
    )
}

