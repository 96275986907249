import React from "react";
import Media from "../../shared/Image";

export interface ProductHeaderProps {
    src: string,
    alt?: string,
}


export default function ProductHeader({src, alt}: ProductHeaderProps) {

    return (
        <div className={'productHeader'}>
            <Media src={src} alt={alt}/>
        </div>
    )
}