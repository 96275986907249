export const SHOP_ACTIONS = {
    ADD_TO_SHOPPINGLIST: 'shop action: add to shopping list',
    REMOVE_FROM_SHOPPINGLIST: 'shop action: remove from shopping list',
}

export const STEPPER_ACTIONS = {
    SET_NEXT: 'stepper action: set next',
    SET_PREV: 'stepper action: set prev',
    SET_STEP: 'stepper action: set step',
    SET_STEPS: 'stepper action: set steps',
    SET_DATA: 'stepper action: set data',
}