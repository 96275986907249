import React, {createContext} from 'react'
import './Product.scss'
import {Paper} from '@mui/material'
import ProductHeader from './components/ProductHeader'
import ProductContent from './components/ProductContent'
import ProductActions from './components/ProductActions'
import {Product} from '../../Api'


export const ProductContext = createContext<Product>({
    id: 0,
    name: '',
    price: 0,
    count: 0,
})

export default function ProductComp({data}: { data: Product }) {

    const getProductHeader = () => {
        if (data.media_src)
            return <ProductHeader src={data.media_src}/>
        else return ''
    }

    return (
        <ProductContext.Provider value={data}>
            <Paper className={'product'}>
                {getProductHeader()}
                <ProductContent headline={data.name} price={data.price}/>
                <ProductActions/>
            </Paper>
        </ProductContext.Provider>
    )
}