import React, {useState} from 'react'
import {TextField, Grid, FormControlLabel, Checkbox, Button, Typography} from '@mui/material'
import SteperActions from '../OrderStep/components/StepperActions'
import {useDispatch, useSelector} from 'react-redux'
import {setData, setNext} from '../../../actions/stepperActions'
import {shopItem} from '../../../reducer/ShopReducer'
import {sendOrder} from '../../../actions/shopActions'
import {async} from 'q'
import {API, orderProducts} from '../../../Api'

export interface State {
    firstname: string,
    lastname: string,
    streetName: string,
    postalCode: string,
    city: string,
    notice?: string,
}

const allErrors: Error<State> = {
    firstname: true,
    lastname: true,
    streetName: true,
    postalCode: true,
    city: true,
}

type Error<Type> = {
    [Property in keyof Type]?: boolean
}

const NAME_REGEX: RegExp = /^([A-Za-z\- —]){2,100}/
const POSTAL_CODE_REGEX: RegExp = /^[0-9]{5}/
const EMAIL_REGEX: RegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/


export default function WichtelStep() {

    const dispatch = useDispatch()

    const [values, setValues] = useState<State>({
        firstname: '', lastname: '', streetName: '', postalCode: '', city: '', notice: ''
    })
    const [errors, setErrors] = useState<Error<State>>({})

    const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            [prop]: event.target.value
        })
    }
    console.log(errors, values)

    const {shop, stepper}: any = useSelector(state => state)


    const validateInput = (prop: keyof State | string): boolean | undefined => {
        console.log('INPUT PROP:', prop)
        switch (prop) {
            case 'firstname':
            case 'lastname':
            case 'city':
            case 'streetName':
                console.log('name, city, street')
                return !NAME_REGEX.test(values[prop])
            case 'postalCode':
                console.log('postal')
                return !(POSTAL_CODE_REGEX.test(values[prop])) || !(values[prop].length === 5)
        }
    }

    const handleFocusLeave = (prop: keyof State) => (event: React.FocusEvent<HTMLInputElement>) => {
        setErrors({...errors, [prop]: validateInput(prop)})
    }


    const handleNext = () => {
        const validation = Object.keys(values).filter((value, index) => {
            return validateInput(value) === true
        })

        if (validation.length === 0) {
            dispatch(setData(values))
            console.log(stepper)
            dispatch(setNext())
        } else {
            console.log(validation)
            setErrors({...allErrors, ...errors})
        }

    }


    return (
        <div className={'orderStep'}>
            <Grid container className="formRow" spacing={2}>
                <Grid item xs={12}>
                    <Typography variant={'body1'}>
                        Bitte gebe hier den <strong>Empfänger</strong> deines Wichtel-Geschenkes ein.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField fullWidth variant={'filled'} label={'Vorname'} required error={errors.firstname}
                               onBlur={handleFocusLeave('firstname')}
                               onChange={handleChange('firstname')}
                               value={values.firstname}
                               id={'firstname'} name={'firstname'}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField fullWidth variant={'filled'} label={'Nachname'} required error={errors.lastname}
                               onBlur={handleFocusLeave('lastname')}
                               onChange={handleChange('lastname')}
                               value={values.lastname}
                               id={'lastname'} name={'lastname'}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth variant={'filled'} label={'Straße'} required error={errors.streetName}
                               onChange={handleChange('streetName')} onBlur={handleFocusLeave('streetName')}
                               value={values.streetName} name={'streetName'} id={'streetName'}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField fullWidth variant={'filled'} label={'PLZ'} required
                               onChange={handleChange('postalCode')}
                               error={errors.postalCode} onBlur={handleFocusLeave('postalCode')}
                               value={values.postalCode} name={'postalCode'} id={'postalCode'}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField fullWidth variant={'filled'} label={'Stadt'} required onChange={handleChange('city')}
                               error={errors.city} onBlur={handleFocusLeave('city')}
                               value={values.city} name={'city'} id={'city'}/>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'body1'}>
                        Hier kannst du dem Empfänger eine persönliche Nachricht hinterlassen
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField onChange={handleChange('notice')} fullWidth multiline variant={'filled'}
                               value={values.notice}
                               name={'notice'}
                               label={'Nachricht'}/>
                </Grid>
            </Grid>
            <SteperActions handleNext={handleNext}/>
        </div>
    )
}