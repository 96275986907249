import React, {useState} from 'react'
import {Skeleton} from "@mui/material";


export interface MediaProps {
    src: string,
    alt?: string,
}

export default function Media({src, alt = ''}: MediaProps) {
    const [loading, setLoading] = useState(true)


    if (loading)
        return (
            <React.Fragment>
                <Skeleton/>
                <img src={src} style={{display: 'none'}} onLoad={() => setLoading(false)}/>
            </React.Fragment>)
    else {
        return <img src={src} alt={alt}/>
    }
}