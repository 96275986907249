import React from 'react'
import {Typography} from '@mui/material'
import './ShoppingCartStep.scss'
import ShoppingList from './components/ShoppingList'
import SteperActions from '../OrderStep/components/StepperActions'
import {useDispatch, useSelector} from 'react-redux'
import {setNext} from '../../../actions/stepperActions'


export default function ShoppingCartStep() {

    const {shop}: any = useSelector(state => state)
    const dispatch = useDispatch()

    const handleNext = () => {
        if (shop.length > 0) {
            dispatch(setNext())
        }
    }

    return (
        <div className={'shoppingCartStep'}>
            <Typography variant={'h3'}>Einkaufswagen</Typography>
            <ShoppingList/>
            <SteperActions handleNext={handleNext} next={shop.length > 0}/>
        </div>
    )
}