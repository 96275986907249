import React from 'react'
import './App.css'
import ProductList from './components/ProductList'
import {theme} from './theme/theme'
import {CssBaseline, ThemeProvider} from '@mui/material'
import Order from './components/Order/Order'
import {Reducer} from './reducer/combineReducer'
import {createStore} from 'redux'
import {Provider} from 'react-redux'
import thunk from 'redux-thunk'
import {applyMiddleware} from 'redux'


const store = createStore(Reducer, applyMiddleware(thunk))


function AppShop() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Provider store={store}>
                <ProductList/>
                <Order wichtel={false}/>
            </Provider>
        </ThemeProvider>
    )
}

export default AppShop
