import {Dispatch} from 'redux'
import {SHOP_ACTIONS, STEPPER_ACTIONS} from './ACTION_TYPES'
import {shopItem} from '../reducer/ShopReducer'
import {State} from '../components/Order/OrderStep/OrderStep'
import {API, orderProducts} from '../Api'


export const removeItem = (id: number) => (dispatch: Dispatch) => {
    dispatch({
        type: SHOP_ACTIONS.REMOVE_FROM_SHOPPINGLIST,
        payload: id,
    })
}

export const addItem = (item: shopItem) => (dispatch: Dispatch) => {
    dispatch({
        type: SHOP_ACTIONS.ADD_TO_SHOPPINGLIST,
        payload: item,
    })
    dispatch({
        type: STEPPER_ACTIONS.SET_STEP,
        payload: 0,
    })
    console.log('ADDING ITEM')
}


export const sendOrder = (inputData: { information: State, order: shopItem[] }) => async (dispatch: Dispatch) => {
    const data = await orderProducts(inputData)
    return data
}