import {SHOP_ACTIONS} from '../actions/ACTION_TYPES'
import {actionType} from './combineReducer'

export interface shopItem {
    id: number,
    name: string,
    price: number,
    count?: number,
}


const defaultState: shopItem[] = []

export default function ShopReducer(state = defaultState, action: actionType) {
    switch (action.type) {
        case SHOP_ACTIONS.ADD_TO_SHOPPINGLIST:
            return [...state, action.payload]
        case SHOP_ACTIONS.REMOVE_FROM_SHOPPINGLIST:
            return [...state.filter(item => item.id !== action.payload)]
        default:
            return state
    }
}