import React, {useState, useContext} from 'react'
import {Button, Icon, IconButton, Slide} from '@mui/material'
import {Check, ShoppingCart} from '@mui/icons-material'
import NumberInput from './NumberInput'
import './ShoppingCartButton.scss'
import {addItem} from '../../actions/shopActions'
import {useDispatch} from 'react-redux'
import {ProductContext} from '../Product/Product'
import {useSelector} from 'react-redux'

import {shopItem} from '../../reducer/ShopReducer'


export default function ShoppingCartButton() {

    const dispatch = useDispatch()
    const {shop}: any = useSelector(state => state)
    const {name, price, id} = useContext(ProductContext)
    const [count, setCount] = useState<number>(0)

    const handleValue = (value: string) => {
        setCount(parseInt(value))
    }
    const handleClick = () => {
        dispatch(
            addItem({name, price, id, count})
        )
    }

    const isAdded: boolean = (shop.filter((item: shopItem) => item.id === id)).length > 0


    return (
        <div className={'shoppingCartButton'}>
            {isAdded ? '' : <NumberInput handleChange={handleValue}/>}
            <Slide in={!isAdded} direction={'left'} mountOnEnter unmountOnExit>
                <Button startIcon={<ShoppingCart/>} variant={'contained'} onClick={handleClick}>
                    <span className="text">Hinzufügen</span>
                </Button>
            </Slide>
            <Slide in={isAdded} direction={'right'} mountOnEnter unmountOnExit>
                <IconButton>
                    <Check/>
                </IconButton>
            </Slide>
        </div>
    )
}