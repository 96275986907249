import React, {useState} from 'react'
import {Typography, Button, IconButton} from '@mui/material'
import {Delete} from '@mui/icons-material'
import {itemProps} from './ShoppingList'
import {removeItem} from '../../../../actions/shopActions'
import {useDispatch} from 'react-redux'

export default function ShoppingListItem({id, name, count, price}: itemProps) {


    const dispatch = useDispatch()
    const [slide, setSlide] = useState<boolean>(true)

    const handleDelete = () => {
        setSlide(false)
        dispatch(removeItem(id))
    }

    return (
        <div className={'shoppingListItem'} key={id}>
            <div className={'name'}>
                <Typography variant={'body2'}>
                    {name}
                </Typography>
            </div>
            <div className={'count'} style={{display: 'flex', alignItems: 'center'}}>
                <Typography variant={'body2'} component={'span'}>
                    Anzahl
                </Typography>
                <Button>
                    {count}
                </Button>
            </div>
            <div className={'sum'}>
                <Typography variant={'body2'} component={'span'}>{price} €</Typography>
                <Button>{price * count}€</Button>
            </div>
            <div>
                <IconButton color={'error'} size={'small'} onClick={handleDelete}>
                    <Delete fontSize={'small'}/>
                </IconButton>
            </div>
        </div>
    )
}