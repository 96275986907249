import React from 'react'
import StepperActions from '../OrderStep/components/StepperActions'
import {Typography, Container} from '@mui/material'


export default function SuccessStep({data}: { data: any }) {


    return (
        <div>
            <Container>
                <Typography variant={'h1'}>
                    DANKE
                </Typography>
                <Typography variant={'body1'}>
                    Wir haben dir gerade eine E-Mail an {data.email} mit allen Informationen geschickt. Bitte überprüfe
                    auch deinen Spam
                    Ordner!
                </Typography>
                <Typography>
                    Bei Fragen zu deiner Bestellung oder Problemen, wende dich bitte an <a
                    href="mailto:new@neustadt.de">new@neustadt.de</a>
                </Typography>
                <Typography variant={'body2'} sx={{my: 3}}>
                    Liebe Grüße, <br/>
                    dein NEW-Perspektiven Team
                </Typography>
            </Container>
            <StepperActions isLast={true}/>
        </div>
    )
}