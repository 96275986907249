import React from "react";
import {Container, Typography} from "@mui/material";

export interface ProductContentProps {
    headline: string,
    price: number,
}


export default function ProductContent({headline, price}: ProductContentProps) {
    return (
        <Container className={'productContent'}>
            <Typography variant={'h5'} component={'p'}>
                {headline}
            </Typography>
            <Typography variant={'body1'} component={'p'}>
                Preis: {price}€
            </Typography>
        </Container>
    )
}