import React from 'react'
import {Typography} from '@mui/material'


export default function PopUpText({data}: { data: any }) {

    const getHtml = () => {
        if (data.overlay !== null)
            return {__html: data.overlay.description}
        return {__html: ''}
    }

    return (
        <div className={'popupText'}>
            <Typography className={'headline'} variant={'h4'} sx={{mt: 3}} component={'h2'}>{data.name}</Typography>
            <Typography className={'bodyText'} variant={'body1'}
                        dangerouslySetInnerHTML={getHtml()}>
            </Typography>

        </div>)
}