import React, {useContext} from 'react'
import {PopupContext} from '../PopUp'
import {Typography} from '@mui/material'


export default function PopUpInfo({data}: { data: any }) {


    return (
        <div className="popupPrice" style={{textAlign: 'right'}}>
            <Typography variant={'h5'}>
                {data.price}€
            </Typography>
            <Typography variant={'body1'}>
                zzgl. Versandkosten: 3,50€
            </Typography>
        </div>)
}