import React, {useState, useEffect} from 'react'
import ProductComponent from './Product/Product'
import {Container, Grid} from '@mui/material'
import PopUp from './PopUp/PopUp'
import {getProducts, Product} from '../Api'


export default function ProductList() {

    const [products, setProducts] = useState<Product[]>([])

    useEffect(() => {
        async function fetchProducts() {
            const products = await getProducts()
            console.log(products)
            setProducts(products)
        }

        fetchProducts()
    }, [])


    return (
        <React.Fragment>
            <Container sx={{my: 4}}>
                <Grid container spacing={2}>
                    {products.map(product =>
                        <Grid item xs={12} md={6} lg={4}>
                            <ProductComponent data={product}/>
                        </Grid>
                    )}
                </Grid>
            </Container>
        </React.Fragment>
    )
}