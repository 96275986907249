import Button from '@mui/material/Button'
import * as React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {setPrev} from '../../../../actions/stepperActions'


interface StepperActionProps {
    handleNext?: () => void,
    next?: boolean,
    isLast?: boolean,
}

export default function StepperActions({handleNext, next = true, isLast = false}: StepperActionProps) {

    const dispatch = useDispatch()
    const {stepper}: any = useSelector(state => state)
    const {activeStep} = stepper

    const handlePrev = () => {
        dispatch(setPrev())
    }

    return (
        <div className={'stepActions'}>
            {activeStep > 0 && !isLast ?
                <Button onClick={handlePrev}> Zurück </Button>
                : ''
            }
            {
                isLast ?
                    '' :
                    <Button variant={'contained'} onClick={handleNext} disabled={!next}>Weiter</Button>

            }
        </div>
    )
}