import React, {useContext} from 'react'
import {Button} from "@mui/material";
import {ShoppingCart} from "@mui/icons-material";
import {PopupContext} from "../PopUp";
import ShoppingCartButton from "../../shared/ShoppingCartButton";


export default function PopUpActions({}) {

    const {onClose} = useContext(PopupContext)

    return (
        <div className={'popupActions'}>
            <Button onClick={onClose}>Abbrechen</Button>
            <ShoppingCartButton/>
        </div>
    )
}